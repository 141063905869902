@use '@angular/material' as mat;
@import 'vendors';
@import 'variables';
@import '~lato-font/scss/_public-api';
@import 'material-design-icons';
@import 'mixins.scss';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($custom-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($opendecide-app-theme);

@include lato-include-font('light');
@include lato-include-font('normal');
@include lato-include-font('semibold');

/* ---- General ---- */
html, body {
    height: 100%;
    font-weight: latoGetWeightForVariant('light');
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-text-stroke: 1px transparent;

    @media print {
        height: auto;
    }
}

a, a:hover, a:active, a:focus {
    text-decoration: none !important;
    cursor: pointer;
}

@media print {
    @page {
        size: A4;
    }
}

/* ---- Angular Material Override ---- */
$mat-snack-bar-spacing-margin: 24px !default;
.mat-snack-bar-container {
    margin-left: $mat-snack-bar-spacing-margin + $sidebar-width !important;

    &.mobile {
        margin-left: 10px !important;
        margin-right: 10px !important;
        margin-bottom: $mobile-appbar-height + 25px !important;
    }

    &.error {
        background-color: mat.get-color-from-palette($opendecide-app-warn, default);
        color: mat.get-color-from-palette($opendecide-app-warn, default-contrast);
    }

    &.warn {
        background-color: mat.get-color-from-palette($opendecide-app-accent, default);
        color: mat.get-color-from-palette($opendecide-app-accent, default-contrast);
    }

    &.success {
        background-color: mat.get-color-from-palette($opendecide-app-success, default);
        color: mat.get-color-from-palette($opendecide-app-success, default-contrast);
    }

    &.info {
        background-color: mat.get-color-from-palette($opendecide-app-primary, default, 0.8);
        color: mat.get-color-from-palette($opendecide-app-primary, default-contrast);
    }
}

.mat-slide-toggle-content {
    white-space: initial !important;
}

.mat-horizontal-content-container {
    flex: 1 0 auto;
}

.mat-horizontal-stepper-header .mat-step-icon, .mat-vertical-stepper-header .mat-step-icon {
    text-align: center;
}

.mat-tooltip {
    white-space: pre-line;
    font-size: mat.font-size($custom-typography, body-1);
}

// FormQuestion
.choice.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 5em;
    min-height: 5em;

    span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
    }
}
mat-radio-group.text-wrap {
    /* Add some space between the options for readability. */
    /* Especially when we align the radio symbol on start position. */
    gap: 2px;

    .mat-radio-container {
        /* The default behavior is center. */
        /* But the radio options are then difficult to tell apart. */
        /* Therefore we align the radio symbol on top position. */
        align-self: start;
        margin-top: 2px;
    }

    .mat-radio-label-content {
        /* Wrap the label text. */
        white-space: normal;
    }
}

// Practice Recommendations on DiagnosticMediator
.recommendation {
    .mat-list-item-content {
        width: 100%;
    }
}

// NewRecommendationModal --> ng-select overflow
.recommendation-manager .mat-dialog-container, .mat-dialog-container .mat-dialog-content {
    overflow: visible !important;
}

// Diagnostic Results
od-diagnostic-mediator, od-diagnostic-factor, od-diagnostic-mediator-company {
    &:not(:first-of-type) {
        .mat-expansion-panel {
            border-top-right-radius: 0 !important;
            border-top-left-radius: 0 !important;
        }
    }

    &:not(:last-of-type) {
        .mat-expansion-panel {
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }

    // Workaround - Bug with backdrop-filter and border-radius on Firefox and Chrome
    .mat-expansion-panel.mat-expanded {
        border-radius: 0 !important;
    }
}

.model-switcher {
    &:not(.mat-checked) {
        .mat-slide-toggle-bar {
            background-color: mat.get-color-from-palette($opendecide-app-primary, default, 0.54) !important;
        }

        .mat-slide-toggle-thumb {
            background-color: mat.get-color-from-palette($opendecide-app-primary, default) !important;
        }
    }
}


/* ---- OpenDecide SVG Loading ---- */
.od-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.od-loading-animation {
    flex: 1 1 auto;

    path {
        stroke: #D9DDDF;
        transform-origin: 100px 100px;
        animation: rotate 1s cubic-bezier(.42, 0, .58, 1) infinite;
    }

    circle {
        stroke: mat.get-color-from-palette($opendecide-app-primary, default);
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
}

/* ---- ng-select ---- */
.ng-select {
    &.ng-invalid.ng-dirty, &.ng-invalid.ng-touched {
        .ng-placeholder, .ng-arrow {
            color: mat.get-color-from-palette($opendecide-app-warn, default);
        }

        .ng-select-container:after {
            border-bottom-color: mat.get-color-from-palette($opendecide-app-warn, default);
        }
    }

    .ng-select-container.ng-appearance-outline {
        min-height: 51.5px; // ng-select Material Theme broken
    }
}

/* ---- Algolia Instant Search ---- */
.ais-Panel {
    margin-bottom: 1em;
}

.ais-RefinementList-searchBox {
    margin-bottom: 0.5em;
}

.ais-RefinementList-count {
    margin-left: 0.5em;
}

.ais-Panel-body {
    .mat-list-text {
        flex-direction: row !important;
        align-items: center;

        .mat-chip-list {
            margin-left: 0.5em !important;
        }
    }
}

/* ---- Dropzone ---- */
.dropzone {
    .dz-message {
        margin: 0;
    }
}

/* ---- Quill Editor ---- */
.ng-invalid.ng-touched {
    & > .ql-toolbar, & > .ql-container {
        border-color: mat.get-color-from-palette($opendecide-app-warn, default);
    }
}

.ql-container {
    font-family: #{$font-family-sans-serif};
    font-size: 1rem;
    height: auto;

    &.ql-snow:not(.ql-disabled) {
        background-color: $white;
    }
}

// Also used for read-only steps
.ql-editor {
    height: auto;

    p {
        margin: 0 !important; // Override Material Theme Typography
    }

    img { // No overflow on practice step
        width: 100%;
        height: auto;
    }
}

/* ---- OpenDecide Nav Search Bar ---- */
.nav-search-bar {
    .mat-form-field-prefix, .mat-form-field-suffix {
        top: 0.1em !important;
        margin-right: 0.3em !important;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0 !important;
    }

    .mat-form-field-infix {
        padding: 0.7em 0 0.6em 0 !important;
        border-top: 0 !important;
        font-size: 0.9em !important;
    }

    @each $position in ('start', 'gap', 'end') {
        .mat-form-field-outline-#{$position} {
            background-color: mat.get-color-from-palette($opendecide-app-primary, darker) !important;
            border-color: mat.get-color-from-palette($opendecide-app-primary, darker);
        }
    }

    .mat-input-element {
        &::placeholder {
            color: mat.get-color-from-palette($opendecide-app-primary, darker-contrast) !important;
            font-size: 0.9em !important;
        }

        &::-webkit-input-placeholder {
            color: mat.get-color-from-palette($opendecide-app-primary, darker-contrast) !important;
            font-size: 0.9em !important;
        }

        &:-ms-input-placeholder {
            color: mat.get-color-from-palette($opendecide-app-primary, darker-contrast) !important;
            font-size: 0.9em !important;
        }
    }

    &.mat-focused {
        .mat-input-element {
            color: mat.get-color-from-palette($opendecide-app-primary, darker) !important;

            &::placeholder {
                color: mat.get-color-from-palette($opendecide-app-primary, darker) !important;
            }

            &::-webkit-input-placeholder {
                color: mat.get-color-from-palette($opendecide-app-primary, darker) !important;
            }

            &:-ms-input-placeholder {
                color: mat.get-color-from-palette($opendecide-app-primary, darker) !important;
            }
        }

        .mat-form-field-prefix, .mat-form-field-suffix {
            color: mat.get-color-from-palette($opendecide-app-primary, darker) !important;
        }

        @each $position in ('start', 'gap', 'end') {
            .mat-form-field-outline-#{$position} {
                background-color: mat.get-color-from-palette($opendecide-app-primary, darker-contrast) !important;
            }
        }
    }
}

/* ---- OpenDecide Language selector ---- */
od-landing-page {
    .mat-form-field-appearance-outline .mat-form-field-prefix {
        top: 0 !important;
        padding-right: 1em;
    }

    .mat-form-field-appearance-outline .mat-form-field-suffix {
        top: 0 !important;
        padding-left: 1em;
    }
}

/* ---- OpenDecide Register Stepper ---- */
form.register {
    .mat-horizontal-stepper-header-container {
        mat-step-header:first-of-type {
            padding-left: 0;
        }

        mat-step-header:last-of-type {
            padding-right: 0;
        }
    }

    .mat-horizontal-content-container {
        padding: {
            left: 0;
            right: 0;
        }
    }
}

/* ---- OpenDecide Landing page product menu ---- */
.mat-menu-panel.landing-header-menu {
    max-width: 100%; // Override Material default

    .mat-menu-content {
        padding: 1rem 0.5rem;
    }

    mat-divider {
        width: 100%;
        margin: {
            top: 0.5rem;
            bottom: 0.5rem;
        }
    }

    .menu-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        padding: 0.5rem;

        span {
            font-size: 1.5rem;
            line-height: 1.5rem;
            margin-right: 1rem;
            color: mat.get-color-from-palette($opendecide-app-primary, default);
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            line-height: 1.5rem;

            .title {
                flex: 0 1 auto;
                line-height: 1rem;
                text-transform: uppercase;
                color: mat.get-color-from-palette($opendecide-app-primary, default);
                font-weight: 500;
            }
        }
    }
}

/* ---- OpenDecide Survey page ---- */
od-survey {
    .mat-form-field-appearance-outline .mat-form-field-prefix,
    .mat-form-field-appearance-outline .mat-form-field-suffix {
        top: 0 !important;
    }
}

/* ---- Intro.js - Sidebar ---- */
.introjs-fixedTooltip {
    left: 0 !important;
}

/* ---- Plyr - Modal ---- */
od-classic-modal {
    --plyr-color-main: #{mat.get-color-from-palette($opendecide-app-primary, default)};
}
